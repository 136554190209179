<template lang="html">
    <div>
        <b-navbar type="light" class="nav-bg" fixed="top">
            <img src="../../assets/img/logo.png" class="rounded mx-auto d-block nav-img" >
        </b-navbar>
        <b-container class="content-section">
            <loading :active.sync="isLoading" :is-full-page="fullPage"></loading>
            <div style="margin-bottom: 100px;" v-show="showOrder">
                <b-row class="mt-2" v-for="order in orders">
                    <b-col>
                        <b-card @click="orderInfoShow(order)">
                             <b-row class="justify-content-md-center">
                                <b-col>
                                    <h3>{{order.name}} ( {{order.location}} )</h3>
                                </b-col>
                            </b-row>
                            <hr class="mt-2 mb-2">
                            <b-row class="justify-content-md-center">
                                <b-col cols="3">
                                    <p>{{order.year}}</p>
                                    <p class="order-day">{{order.day}}</p>
                                    <p>{{order.month}}</p>
                                </b-col>
                                <b-col cols="5">
                                    <p>{{order.invoice}}</p>
                                    <p>{{order.phone}}</p>
                                    <p>{{order.totalPrice}} Ks</p>
                                </b-col>
                                <b-col cols="4">
                                    <p>-</p>
                                    <p class="text-capitalize">{{order.status}}</p>
                                    <p>-</p>
                                </b-col>
                            </b-row>
                        </b-card>
                    </b-col>
                </b-row>
            </div>
            <div class="row status-info" v-show="showOrderInfo">
                <div class="col-md-12 ">
                    <b-row class="mt-2 mb-2" v-for="(item, index) in orderItems">
                        <b-col>
                            <b-card>
                                <b-row class="justify-content-md-center">
                                    <b-col>
                                        <h3>
                                            {{item.itemName}} 
                                            <span v-if="errorIndex == index" class="text-danger">{{error}}</span>
                                        </h3>
                                    </b-col>
                                </b-row>
                                <hr class="mt-2 mb-2">
                                <b-row class="justify-content-md-center">
                                    <b-col cols="6">
                                        <p class="mb-1">Qty</p>
                                        <p class="mb-1">Stock</p>
                                        <p class="mb-1">Damage</p>
                                        <p>Price</p>
                                    </b-col>
                                    <b-col cols="6">
                                        <p class="mb-1">
                                            <input type="number" v-model="item.quantity" @blur="calcQty(item, index)" class="form-control" style="height: 26px;">
                                        </p>
                                        <p class="mb-1">
                                            <input type="number" v-model="item.stock" disabled class="form-control" style="height: 26px;">
                                        </p>
                                        <p class="mb-1">
                                            <input type="number" v-model="item.damage_quantity" disabled class="form-control" style="height: 26px;">
                                        </p>
                                        <p>
                                            <input type="number" v-model="item.price" disabled class="form-control" style="height: 26px;">
                                        </p>
                                    </b-col>
                                </b-row>
                                <hr class="mt-2 mb-2">
                                <b-row class="justify-content-md-center">
                                    <b-col>
                                        <h3></h3>
                                        <h3>Total Price : {{item.price * item.quantity}} Ks</h3>
                                    </b-col>
                                </b-row>
                            </b-card>
                        </b-col>
                    </b-row>
                    <b-row class="mt-2 mb-2">
                        <b-col>
                            <b-card class="bg-primary">
                                <b-row class="justify-content-md-center">
                                    <b-col>
                                        <h3 class="text-white">Total Price : {{Total}} Ks</h3>
                                    </b-col>
                                </b-row>
                            </b-card>
                        </b-col>
                    </b-row>
                    <b-row class="mt-2 mb-2" v-if="validationData.assignDate">
                        <b-col>
                            <b-card class="bg-danger">
                                <b-row class="justify-content-md-center">
                                    <b-col>
                                        <h3 class="text-white">{{validationData.assignDate}}</h3>
                                    </b-col>
                                </b-row>
                            </b-card>
                        </b-col>
                    </b-row>
                    <b-row class="mt-2 mb-2" v-if="validationData.deliveryId">
                        <b-col>
                            <b-card class="bg-danger mt-1">
                                <b-row class="justify-content-md-center">
                                    <b-col>
                                        <h3 class="text-white">{{validationData.deliveryId}}</h3>
                                    </b-col>
                                </b-row>
                            </b-card>
                        </b-col>
                    </b-row>
                    <form v-on:submit.prevent="onSubmit">
                        <input type="date" v-model="statusRequest.assignDate" class="form-control">
                        <select v-model="statusRequest.deliveryId" class="form-control mt-2">
                            <option value="">Assign Delivery</option>
                            <option :value="delivery.id" v-for="delivery in deliveries">{{delivery.name}}</option>
                        </select>
                        <select v-model="statusRequest.status" class="form-control mt-2">
                            <option value="pending">Pending</option>
                            <option value="approved">Approved</option>
                            <option value="reject">Reject</option>
                            <option value="shipping" disabled>Shipping</option>
                            <option value="completed" disabled>Completed</option>
                        </select>
                        <textarea class="form-control mt-2" v-model="statusRequest.remark"></textarea>
                        <button class="btn btn-success mt-2" style="width: 100%;" v-if="error == ''">Submit</button>
                        <a class="btn btn-success mt-2 text-white" style="width: 100%;"
                            @click="closeOrderInfo()">Close</a>
                    </form>
                </div>
            </div>
            <!-- <router-link class="float-filter" to="">
                <i class="fa fa-filter my-float" style="padding: 37%;bottom: 50px;"></i>
            </router-link> -->
        </b-container>
    </div>
</template>

<script lang="js">
    import {
        mapState,
        mapActions
    } from 'vuex'
    import Loading from 'vue-loading-overlay'
    import 'vue-loading-overlay/dist/vue-loading.css';
    export default {
        name: 'item',
        components: {
            Loading
        },
        data() {
            return {
                isLoading: false,
                fullPage: true,
                showOrderInfo: false,
                showOrder: true,
                orders: [],
                orderItems: [],
                errorIndex: null,
                error : "",
                totalPrice: '',
                statusRequest: {
                    "deliveryId": "",
                    "status": "pending",
                    "assignDate": "",
                    "orderId": "",
                    "remark": ""
                },
                validationData: {
                    assignDate: "",
                    deliveryId: "",
                    itemError: ""
                }
            }
        },
        computed: {
            ...mapState({
                deliveries: state => state.deliveries,
            }),
            Total() {
                let total = 0;
                this.orderItems.forEach(item => {
                    total += parseInt(item.price) * parseInt(item.quantity);
                });
                this.totalPrice = total
                return total;
            }
        },
        methods: {
            ...mapActions({
                orderListAction: 'orderListAction',
                deliveryListAction: 'deliveryListAction',
                statusChangeAction: 'statusChangeAction'
            }),
            async orderList() {
                this.isLoading = true;
                await this.orderListAction().then(res => {
                    if (res.status == "success") {
                        this.orders = res.data
                        this.isLoading = false
                    }
                }).catch(err => this.isLoading = true)
            },
            calcQty(item, index){
                if(this.errorIndex != null){
                    if(this.errorIndex != index){
                        if(this.error != ""){
                            this.error = "some item is error"
                            return
                        }
                    }
                }

                this.errorIndex = index
                if(item.quantity > item.stock){
                    this.error = "Quantity greater than stock"
                }else if(item.quantity < 0){
                    this.error = "Quantity less than 0"
                }else if(item.quantity == ""){
                    this.error = "Quantity is required"
                }else{
                    this.error = ""
                }
            },
            orderInfoShow(order) {
                this.showOrder = false
                this.showOrderInfo = true
                this.orderItems = order.item
                this.totalPrice = order.totalPrice
                this.statusRequest.status = order.status
                this.statusRequest.orderId = order.id
                this.statusRequest.remark = order.remark
                if(order.deliveryId){
                    this.statusRequest.deliveryId = order.deliveryId
                }else{
                    this.statusRequest.deliveryId = ""
                }
                this.statusRequest.assignDate = order.assign_date
            },
            closeOrderInfo() {
                this.showOrder = true
                this.showOrderInfo = false
            },
            async onSubmit() {
                this.isLoading = true
                let validation = this.validationRule()
                if (validation == true) {
                    this.isLoading = false
                    return
                }
                let option = {
                    item: this.orderItems,
                    orderId: this.statusRequest.orderId,
                    deliveryId: this.statusRequest.deliveryId,
                    status: this.statusRequest.status,
                    assignDate: this.statusRequest.assignDate,
                    remark: this.statusRequest.remark
                }
                await this.statusChangeAction({
                    ...option
                }).then(res => {
                    this.isLoading = false
                    this.statusRequest.orderId = ""
                    this.statusRequest.deliveryId = ""
                    this.statusRequest.status = ""
                    this.statusRequest.assignDate = ""
                    this.statusRequest.remark = ""
                    this.orderList()
                    this.closeOrderInfo()
                }).catch(err => this.isLoading = true)
            },
            validationRule() {
                let isValidation = []
                if (this.statusRequest.assignDate == null) {
                    this.validationData.assignDate = "The assign date field is required."
                    isValidation.push(true)
                } else {
                    this.validationData.assignDate = ""
                    isValidation.push(false)
                }
                if(this.statusRequest.status == "approve"){
                    if (this.statusRequest.deliveryId == "") {
                        this.validationData.deliveryId = "The assign delivery field is required."
                        isValidation.push(true)
                    } else {
                        this.validationData.deliveryId = ""
                        isValidation.push(false)
                    }
                }

                if (isValidation.includes(true)) {
                    return true
                } else {
                    return false
                }
            }
        },
        async mounted() {
            this.isLoading = true
            this.orderList()
            await this.deliveryListAction()
        }
    }
</script>

<style scoped lang="scss">
    .float-filter {
        position: fixed;
        width: 60px;
        height: 60px;
        bottom: 90px;
        right: 2%;
        background-color: #318be3;
        color: #FFF;
        border-radius: 50px;
        text-align: center;
        box-shadow: 2px 2px 3px #999;
    }

    .order-day {
        font-size: larger;
        font-weight: bold;
        font-style: italic;
    }

    .status-info {
        height: 200vh;
        background: #f8f9fa;
        z-index: 9999;
        position: absolute;
        width: 100%;
    }

    .card-scroll {
        margin-bottom: 100px;
        overflow: scroll;
    }
        /* Hide scrollbar for Chrome, Safari and Opera */
    .card-scroll::-webkit-scrollbar {
        display: none;
    }

    /* Hide scrollbar for IE, Edge and Firefox */
    .card-scroll {
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
    }
</style>